import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, message, Divider, Button,
} from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';

import {
  AuthSelectors, LoadingSelectors, ChannelsSelectors,
} from '../../redux/reducers';
import {
  UtilsActions, CondominiumActions, ChannelsActions,
} from '../../redux/actions';
import { isAdmin } from '../../utils/permissions';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSwitch from '../../../components/shared/AdvancedSwitch/AdvancedSwitch';
import DataTable from '../../../components/shared/DataTable/DataTable';
import AdvancedUpload from '../../../components/shared/AdvancedUpload/AdvancedUpload';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import DwellerTableModal from '../../../components/panel/dwellerTableModal/DwellerTableModal';

const ImportDwellersType = {
  SELECT_CONDOMINIUM: 1,
  IMPORT_CSV: 2,
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Você só pode enviar arquivos no formato JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 3;
  if (!isLt2M) {
    message.error('A imagem deve ser menor ou igual a 3MB!');
  }
  return isJpgOrPng && isLt2M;
}

class CondominiumForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoading: true,
      tableModalVisible: false,
      importUsers: [],
      removeUsers: [],
      form: {
        name: '',
        title: '',
        description: '',
        iconUrl: null,
        isDefault: false,
        isHome: false,
        isFixed: false,
        importType: ImportDwellersType.SELECT_CONDOMINIUM,
        condominiumId: '',
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { details, id } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          name: details.name,
          title: details.title,
          description: details.description,
          iconUrl: details.iconUrl,
          isDefault: details.isDefault,
          isHome: details.isHome,
          isFixed: details.isFixed,
          condominiumId: details.condominiumId,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { details, submitFunction, me } = this.props;
    const { form, importUsers, removeUsers } = this.state;
    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      title: yup.string().required(I18n.t('shared.errors.missing_title')),
      description: yup.string().required(I18n.t('shared.errors.missing_description')),
      condominiumId: yup.string().required(I18n.t('shared.errors.missing_condominiumId')),
    });

    if (form.importType === ImportDwellersType.IMPORT_CSV && importUsers.length === 0) {
      return message.error(I18n.t('routes.panel.communication.items.channel.details.errors.missing_csv'));
    }

    if (!form.iconUrl) {
      return message.error(I18n.t('routes.panel.communication.items.channel.details.errors.missing_photo'));
    }

    let fixedImage = form.iconUrl && form.iconUrl.url && form.iconUrl.url.indexOf('?');
    if (fixedImage && fixedImage > 0) {
      fixedImage = form.iconUrl.url.split('?');
    }

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const data = {
          ...form,
          ...(isAdmin(me) && form.condominiumId && { condominiumId: form.condominiumId }),
          ...(!isAdmin(me) && { condominiumId: me.condominiumId }),
          ...(form.importType === ImportDwellersType.IMPORT_CSV && { addUsers: importUsers }),
          ...(form.importType === ImportDwellersType.SELECT_CONDOMINIUM && { everyoneInCondominium: true }),
          ...(form.iconUrl && form.iconUrl.url && { iconUrl: fixedImage[0] }),
          ...(!form.isDefault && removeUsers.length > 0 && { removeUsers }),
        };

        if (details) {
          submitFunction(details.id, data);
        } else {
          submitFunction(null, data);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  getPaginatedData(params) {
    const { getPaginated, id } = this.props;
    const { firstLoading } = this.state;
    params = {
      ...params,
    };
    getPaginated(id, params, () => {
      if (firstLoading) this.setState({ firstLoading: false });
    });
  }

  applyOnSelectedList(userId) {
    const { removeUsers } = this.state;
    if (removeUsers.find((item) => item === userId)) {
      this.setState((prevState) => ({
        removeUsers: [...prevState.removeUsers].filter((item) => item !== userId),
      }));
    } else {
      this.setState((prevState) => ({
        removeUsers: prevState.removeUsers.concat(userId),
      }));
    }
  }

  applyOnImportUser(userId) {
    const { importUsers } = this.state;
    if (importUsers.find((item) => item === userId)) {
      this.setState((prevState) => ({
        importUsers: [...prevState.importUsers].filter((item) => item !== userId),
      }));
    } else {
      this.setState((prevState) => ({
        importUsers: prevState.importUsers.concat(userId),
      }));
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  render() {
    const {
      loading, uploadImage, me, id, channelUser,
    } = this.props;
    const {
      form, tableModalVisible, firstLoading, removeUsers, importUsers,
    } = this.state;

    return (
      <Row>
        {(loading === 0 || !firstLoading) && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.communication.items.channel.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.communication.items.channel.details.form.title.label')}
                    value={form && form.title}
                    onChange={(val) => this.fieldChange('title', val)}
                  />
                </Col>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.communication.items.channel.details.form.description.label')}
                    value={form && form.description}
                    onChange={(val) => this.fieldChange('description', val)}
                  />
                </Col>
              </Row>

              <Row
                gutter={16}
                type="flex"
                align="middle"
              >

                <Col span={10}>
                  <Row gutter={16}>
                    <Col span={10}>
                      <AdvancedUpload
                        label={I18n.t('routes.panel.communication.items.channel.details.form.iconUrl.label')}
                        pictureUrl={form && ((form.iconUrl && form.iconUrl.base64) || form.iconUrl)}
                        compressImage={false}
                        onUpload={(photoData, photoName, photoType) => uploadImage(photoData, photoName, photoType)}
                        onChange={(val) => this.fieldChange('iconUrl', val)}
                        beforeUpload={beforeUpload}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedSwitch
                        label={I18n.t('routes.panel.communication.items.channel.details.form.isDefault.label')}
                        value={form && form.isDefault}
                        onChange={(val) => this.fieldChange('isDefault', val)}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedSwitch
                        label={I18n.t('routes.panel.communication.items.channel.details.form.isHome.label')}
                        value={form && form.isHome}
                        onChange={(val) => this.fieldChange('isHome', val)}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedSwitch
                        label={I18n.t('routes.panel.communication.items.channel.details.form.isFixed.label')}
                        value={form && form.isFixed}
                        onChange={(val) => this.fieldChange('isFixed', val)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {id !== 'add' && (
                <>
                  <Divider />
                  <DataTable
                    getMethod={(parameters) => this.getPaginatedData(parameters)}
                    data={channelUser}
                    loading={!channelUser}
                    ref={this.dataTableRef}
                    columns={
                      [
                        {
                          key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.name.key'),
                          title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.name.title'),
                          render: (value) => value || '--',
                        },
                        {
                          key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.email.key'),
                          title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.email.title'),
                          render: (value) => value || '--',
                        },
                        {
                          key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.condominiumUser.key'),
                          title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.condominiumUser.title'),
                          render: (i) => (i && (
                            <p key={i.id}>
                              {`${i.block ? `Bloco ${i.block},` : ''} ${i.number ? `Número ${i.number}` : ''}`}
                            </p>
                          )),
                        },
                        {
                          key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.email.key'),
                          title: '',
                          render: (userId) => (
                            <div className="dataTable__item--right">
                              <Button
                                type="link"
                                onClick={() => this.applyOnSelectedList(userId)}
                                disabled={form && form.isDefault}
                              >
                                {removeUsers.find((item) => item === userId) ? I18n.t('routes.panel.communication.items.channel.details.remove') : (
                                  <>
                                    {I18n.t('routes.panel.communication.items.channel.details.selectRemove')}
                                    <DeleteOutlined />
                                  </>
                                )}
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    }
                  />
                </>
              )}

              <Divider />
              <Row>
                <Col
                  span={24}
                >
                  <AdvancedRadioGroup
                    label={I18n.t('routes.panel.communication.items.channel.details.radioButton.title')}
                    onChange={(e) => this.fieldChange('importType', e.target.value)}
                    value={form && form.importType}
                    options={[
                      {
                        id: ImportDwellersType.SELECT_CONDOMINIUM,
                        name: isAdmin(me)
                          ? I18n.t('routes.panel.communication.items.channel.details.radioButton.firstOptionAdmin')
                          : I18n.t('routes.panel.communication.items.channel.details.radioButton.firstOption'),
                      },
                      { id: ImportDwellersType.IMPORT_CSV, name: I18n.t('routes.panel.communication.items.channel.details.radioButton.secondOption') },
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  span={8}
                >
                  {isAdmin(me) && (
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(condominiumId) => this.fieldChange('condominiumId', condominiumId)}
                      value={form && form.condominiumId}
                      disableSearch
                      disableButton={id !== 'add'}
                      disabled={id !== 'add'}
                    />
                  )}

                  {form && form.importType === ImportDwellersType.IMPORT_CSV && (
                    <AdvancedButton
                      type="primary"
                      loading={loading && loading > 0}
                      onClick={() => this.setState({ tableModalVisible: true })}
                      key="upload"
                      text={I18n.t('routes.panel.communication.items.channel.details.import')}
                      disabled={isAdmin(me) && form && !form.condominiumId}
                    />
                  )}

                  {tableModalVisible && (
                    <DwellerTableModal
                      visible
                      handleCancel={() => this.setState({ tableModalVisible: false })}
                      condominiumId={isAdmin(me) ? form && form.condominiumId : me.condominiumId}
                      applyOnImportUser={(userEmail) => this.applyOnImportUser(userEmail)}
                      importUsers={importUsers}
                    />
                  )}

                </Col>

              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.communication.items.channel.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: ChannelsSelectors.getDetails(state),
  channelUser: ChannelsSelectors.getUserPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  uploadImage: (photoData, photoName, photoType, callback) => dispatch(UtilsActions
    .uploadImage(photoData, photoName, photoType, callback)),
  getPaginated: (id, parameters) => dispatch(ChannelsActions.getUsersPaginated(id, parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CondominiumForm);
